import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from "./index";
import {getProductsBy} from "../api/products";
import { sizes, priceRange } from "../utilities/constants";
import {CategoriesStore} from './categories';
import {ProductStore} from './product';
import { OverlayStore } from "@/store/overlayLoader";

@Module({ dynamic: true, store, name: 'shop', namespaced: true })
export default class shop extends VuexModule {
    CategoriesStore: any = CategoriesStore;
    ProductStore: any = ProductStore;
    shopProducts:any[] = [];
    sizeFilters:any = sizes;
    priceRange:any = priceRange;
    filterCategories:any[]=[];
    filterColors:any[]=[];
    sortBy:String = 'popularity';
    pageNum: number = 1;
    get categoryIdArrayString(){
        const selectedIds = this.filterCategories
        .filter((cat) => cat.selected == true)
        .map((cat) => cat.id);
        let categoryStringArray: any = "";
        selectedIds.forEach(
        (id, i) =>
            (categoryStringArray += id + (i === selectedIds.length - 1 ? "" : ","))
        );
        return categoryStringArray;
    }
    get colorsStringArray(){
        const selectedIds = this.filterColors
        .filter((cat) => cat.selected == true)
        .map((cat) => cat.id);
        let colorIdStringArray: any = "";
        selectedIds.forEach(
        (id, i) =>
            (colorIdStringArray += id + (i === selectedIds.length - 1 ? "" : ","))
        );
        return colorIdStringArray;
    }
    get sizesStringArray(){
        let retVal = '';
        Object.entries(this.sizeFilters).forEach((keyValue, i)=>{
            if(keyValue[1] === true){
                retVal += keyValue[0] + (i === Object.entries(this.sizeFilters).length-1 ? '' : ',' );
            }
        })
        return retVal;
    }
    get pricesStringArray(){
        let retVal = '';
        Object.entries(this.priceRange).forEach((keyValue:any, i)=>{
            if(keyValue[1]['value'] === true){
                retVal += keyValue[0] + (i === Object.entries(this.priceRange).length-1 ? '' : ',' );
            }
        })
        return retVal;
    }
    get queryParams (){
        let query:any = {
            sort_by: this.sortBy,
            page: this.pageNum,
        }
        if(this.categoryIdArrayString != ''){
            query.category = String(this.categoryIdArrayString);
        }
        if(this.sizesStringArray != ''){
            query.size = this.sizesStringArray;
        }
        if(this.pricesStringArray != ''){
            query.price = this.pricesStringArray;
        }
        if(this.colorsStringArray != ''){
            query.color = this.colorsStringArray;
        }
        return query
    }
    get FilterCount(){
        let retval = 0;
        const categoryCount = this.filterCategories
        .filter((cat) => cat.selected == true).length;
        const sizeCount = Object.values(this.sizeFilters).filter((value)=>value ==true).length;
        const priceCount = Object.values(this.priceRange).filter((value:any)=>value['value'] === true).length;
        const colorCount = this.filterColors
        .filter((cat) => cat.selected == true).length;
        retval = categoryCount + sizeCount + priceCount + colorCount;
        return retval;
    }
    
    @Mutation
    initFilterColors(){
        let allColors = ProductStore.allColors;
        this.filterColors =  allColors.map(cat =>{return {...cat, selected: false}});
    }
    @Mutation
    initFilterCategories(){
        let allSubcategories = CategoriesStore.allSubcategories;
        this.filterCategories =  allSubcategories.map(cat =>{return {...cat, selected: false}});
    }
    @Mutation
    setSizeFilters(params : any){
        this.sizeFilters[params.key] = params.value;
    }
    @Mutation
    setPriceRangeFilter(params : any){
        this.priceRange[params.key]['value'] = params.value;
    }
    @Mutation
    setShopProducts(data: object[]):void {
        this.shopProducts = data;
    }
    @Mutation
    setfilterCategory(params : any){
        let found = this.filterCategories.find(cat => cat.id == params.id);
        if(found){
            found.selected = params.val;
        }
    }
    @Mutation
    setfilterColor(params : any){
        let found = this.filterColors.find(cat => cat.id == params.id);
        if(found){
            found.selected = params.val;
        }
    }
    @Mutation
    clearFilterCategory(){
        this.filterCategories.forEach(cat => cat.selected = false);
    }
    @Mutation
    setfilterSize(params : any){    
        if(this.sizeFilters[String(params.id)] !== undefined && this.sizeFilters[String(params.id)] !== null){
            this.sizeFilters[String(params.id)] = params.val
        }
    }
    @Mutation
    clearFilterSize(){
        Object.keys(this.sizeFilters).forEach((key:any)=> this.sizeFilters[key] = false)
    }
    @Mutation
    clearFilterColor(){
        this.filterColors.forEach(cat => cat.selected = false);
    }
    @Mutation
    setFilterPrice(params : any){    
        if(this.priceRange[String(params.id)] !== undefined && this.priceRange[String(params.id)] !== null){
            this.priceRange[String(params.id)]['value'] = params.val
        }
    }
    @Mutation
    clearFilterPrice(){
        Object.keys(this.priceRange).forEach((key:any)=> this.priceRange[key]['value'] = false)
    }
    @Mutation
    setSortBy(params : any){
        this.sortBy = params;
    }
    @Mutation
    setPageNum(params : any){
        this.pageNum = params;
    }
    @Action
async fetchShopProducts(params: any) {
    try {
        OverlayStore.setLoader(true);

        const response = await getProductsBy(params);

        if (response !== null) {
            this.setShopProducts(response);
        } else {
            console.warn('No products found or an empty response received.');
        }
    } catch (error) {
        console.error('Error fetching shop products:', error);
    } finally {
        OverlayStore.setLoader(false);
    }
}

}
export const ShopStore = getModule(shop);