var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.product)?_c('div',{staticClass:"relative w-full h-full group cursor-pointer rounded group"},[(_vm.CustomerStore.token)?_c('button',{staticClass:"h-7 w-7 flex rounded-full focus:outline-none absolute right-0 top-0 m-1 z-20 hidden group-hover:block translate-animation transform hover:scale-110",on:{"click":function($event){$event.preventDefault();return (() => {
        _vm.CustomerStore.addLike(_vm.product.id);
        _vm.CustomerStore.fetchLikedProducts(_vm.product.id);
        _vm.product.is_liked = !_vm.product.is_liked;
      }).apply(null, arguments)}}},[(!_vm.product.is_liked)?_c('i',{staticClass:"fas fa-heart m-auto text-black pt-5"}):_vm._e(),(_vm.product.is_liked)?_c('i',{staticClass:"fas fa-heart m-auto text-red-700 pt-5"}):_vm._e()]):_vm._e(),_c('div',{staticClass:"h-5/6 max-height-5/6",on:{"click":() => {
        _vm.$router.push({
        path: `/product-details/${_vm.product.id}`,
        query: { color: _vm.selectedColor },
      }).catch(() => {});
      }}},[(
        _vm.imagesPerColor &&
          _vm.imagesPerColor.length &&
          _vm.imagesPerColor.length >= 1 &&
          _vm.imagesPerColor[0].url
      )?[_c('img',{staticClass:"w-full h-full block group-hover:hidden pt-5",attrs:{"src":_vm.imageURLprefix + _vm.imagesPerColor[0].url,"alt":""}}),(_vm.imagesPerColor.length >= 2 && _vm.imagesPerColor[1].url)?_c('img',{staticClass:"w-full h-full max-h-full hidden group-hover:block pt-5",attrs:{"src":_vm.imageURLprefix + _vm.imagesPerColor[1].url,"alt":""}}):_c('img',{staticClass:"w-full h-full hidden group-hover:block pt-5",attrs:{"src":_vm.imageURLprefix + _vm.imagesPerColor[0].url,"alt":""}})]:[_c('img',{staticClass:"w-full h-full block group-hover:hidden pt-5",attrs:{"src":`${_vm.product.img_url_1}`,"alt":""}}),_c('img',{staticClass:"w-full h-full hidden group-hover:block pt-5",attrs:{"src":`${_vm.product.img_url_2}`,"alt":""}})]],2),_c('div',{staticClass:"h-1/6 max-height-1/6"},[_c('div',{staticClass:"flex items-center"},[(_vm.product.name)?_c('h3',{staticClass:"text-left truncate font-medium mt-3 mr-1",on:{"click":() => {
            _vm.$router.push(`/product-details/${_vm.product.id}`).catch(() => {});
          }}},[_vm._v(" "+_vm._s(_vm.product.name)+" "),_c('div',{staticClass:"group-hover:hidden block clear-fix"}),_c('div',{staticClass:"underline-animation group-hover:block hidden"})]):_vm._e()]),(_vm.product.price)?_c('p',{staticClass:"text-sm text-left mt-1 text-gray-500"},[(_vm.product.sale_price && _vm.product.price)?_c('span',{staticClass:"text-red-550 line-through mr-3"},[(_vm.product.sale_price && _vm.product.price)?[_vm._v(" "+_vm._s(_vm._f("monetary")(_vm.product.price))+" ")]:_vm._e()],2):_vm._e(),(_vm.product.sale_price && _vm.product.price)?[_vm._v(" "+_vm._s(_vm._f("monetary")(_vm.product.sale_price))+" ")]:(_vm.product.price)?[_vm._v(" "+_vm._s(_vm._f("monetary")(_vm.product.price))+" ")]:_vm._e()],2):_vm._e(),_c('div',{staticClass:"flex flex-wrap items-center mx-auto mt-1 space-x-2"},[(_vm.productColors && _vm.productColors.length > 0)?_vm._l((_vm.productColors),function(color){return _c('div',{key:color.id,staticClass:"flex justify-center items-center m-2"},[(color.color_image_file && color.color_image_file.url)?_c('div',{staticClass:"ring ring-offset-3 border-2 rounded-full h-5 w-5 hover:cursor-pointer",class:{
              'ring-black': _vm.selectedColor.id === color.id,
              'ring-gray-200': _vm.selectedColor.id !== color.id,
            },on:{"click":function($event){return _vm.setColor(color)}}},[_c('img',{staticClass:"colorImage",attrs:{"src":_vm.imageURLprefix + color.color_image_file.url}})]):_vm._e()])}):_vm._e()],2)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }